
import React from 'react'
import './client.css'
import { Link } from 'react-router-dom'

function Clients() {
  return (
    <div className='clients '>
        <h1>  تحتاج للمساعدة؟ تواصل معنا الأن</h1>
        <div className='Clients_content'>

        <div className='clients_img'>
            <img src='https://rowadel-3alam.com/wp-content/uploads/2023/02/%D8%B4%D8%B1%D9%83%D8%A9-%D9%86%D9%82%D9%84-%D8%B9%D9%81%D8%B4-%D8%A8%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6-%D8%AA%D9%88%D8%A7%D8%B5%D9%84-%D9%85%D8%B9-%D8%B4%D8%B1%D9%83%D8%A9-%D8%B1%D9%88%D8%A7%D8%AF-%D8%A7%D9%84%D8%B9%D8%A7%D9%84%D9%85-Contact-us.jpg' />

            
        </div>

        {/* end  */}

        <div className='clients_info'>
            <h2>Contact Us: </h2>
            <p>
                
نحن شركة فيوتشر ننتظر اتصالك بنا في أي وقت ومن أي مكان، ولذلك وفرنا لك كل سبل الاتصال السريع والمريح، فيمكنك التواصل معنا عبر أرقامنا المختلفة سواء خطوط الهاتف الأرضي أو الجوال، كما يمكنك مراسلتنا على الواتس اب وسوف يجيب عليك مندوب خدمة العملاء في أسرع وقت.

            </p>
            <div>
                <h2>الاتصال بموقعنا:-</h2>
                <p>
                نحن نتعامل مع بيانات العميل كبيانات سرية
                ، ونقوم بجمع المعلومات عنك بالقدر الذي يسمح لنا بتقديم الخدمات التي تبحث عنها، فأفاقنا مفتوحة
                 لسماع الاقتراحات والملاحظات والشكاوى كذلك، ونحن كأي موقع 
                رسمي نتبع الإجراءات القانونية التي تحافظ على بيانات عملاؤنا.

                </p>
            </div>
            {/* End one div */}
            <div>
                <h2>
                متاحين على مدار اليوم:-
                </h2>
                <p>
                في أي وقت يمكنك التواصل معنا، فلا يهمك حتى لو كان اليوم هو يوم عطله، فنحن نعمل في وقت الإجازات، فتواصل معنا بالمجان لتسأل عن كل شيء يدور في ذهنك، ولا تخجل من طرح كل الاستفسارات فهذا حقك علينا، فمثلًا يمكنك السؤال عن نوعية المواد المستخدمة في عملية التنظيف ونوعية المبيدات الحشرية التي نعتمدها، فتلك الأسئلة تسعدنا لأننا نوفر أفضل شيء ونعرف أننا سنرضيك بالإجابات التي سنضعها لأسئلتك، فنحن شركة لها تاريخ مع تقديم خدمات نقل العفش بالرياض و الخدمات المتنوعة والمختلفة.

                </p>
            </div>
            {/* End Second div */}
            <div>
                <h2>
                الاتصال لطلب الخدمات:-
                </h2>
                <p>
                بمجرد تواصلك مع خدمة العملاء سوف يقوم موظفنا المدرب على أعلى مستوى بالإجابة عن كافة الأسئلة والاستفسارات التي تطرحها حول الخدمات التي نقدمها، ونحن في الأساس شركة نقل عفش تغطي أنحاء مدينة الرياض بالمملكة العربية السعودية، وبالطبع تفرعت الأن خدماتنا ولدينا خدمات تغليف الأثاثوتخزينه، فضلًا عن خدمات النظافة ورش المبيدات الحشرية، وتقديم أفضل صيانه وتنظيف للمكيفات، وخدمات عزل الأسطح، وتصليح تسريبات المياه، وفي القريب العاجل سنوفر المزيد من الخدمات.

                </p>
            </div>
            {/* End div third */}

            <div>
                <h2>
                التواصل من أجل التعاقد على الخدمة
                </h2>

                <p>
                كما تهتم بسرعة تنفيذ الخدمات نحن كذلك نهتم، فوفتك ووقتنا ثمين، ولذلك سيوضح لك موظفنا موعد حضور فريق العمل للعنوان الذي تحدده، وسوف نحدد لك الوقت التقريبي الذي نحتاجه لتقديم لك خدمة ذات مستوى مثالي، وبالطبع تلك الأمور ستحتاج لمعرفة نوع الخدمة بالتحديد، وربما قبل تنفيذها نرسل لك مندوب من شركتنا يقوم بفحص المكان، حتى يضع تقرير شامل عن التجهيزات التي سنعتمد عليها للبدء في العمل.
                </p>
            </div>

            {/* End Fourth */}
            <div>
            <h2>
            هل التواصل مع الشركة يستلزم التعاقد
            </h2>

            <p>
            واصلك معنا خدمة مجانية لا تحملك أي تكاليف أو مسؤولية، فحتى لو سألت فقط ولم تطلب الخدمة فهذا حقك، فنحن نضع أمامك تفاصيل الخدمات التي تسأل عنها، ويمكنك بعدها التفكير قبل التعاقد، وإن كان لدينا يقين أنك ستعاود الاتصال بنا التعاقد. على الخدمات، لأنك لو قارنت ما نقدم مع ما تقدمه الشركات الأخرى، ستجدنا فوق مستوى المنافسة، فمؤسستنا محترفة في عملها.
            </p>
            </div>
            {/* end five */}

            <div>
                <h2>
                تواصل معنا لتقديم الشكاوى
                </h2>

                <p>
                أي كانت الخدمات التي طلبتها وقمنا بتنفيذها، نحن جاهزين لسماع الشكاوى حولها، فلو أصاب عفشك خدش أثناء عملية التغليف والنقل أو التخزين فنحن سوف نعوضك عن ذلك، ولو لم تكن مثلًا خدمات التنظيف ليس بالمستوى المطلوب سوف نصحح هذا الخطأ ونعاود لتنظيف المكان بشكل مجاني.

ونفس الأمر فيما يتعلق بتنظيف المكيفات وصيانتها، وكذلك نتابع معك تصليح مشكلة تسريب المياه حتى نضمن أن المشكلة انتهت تمامًا، وبالنسبة لعزل الأسطح فنحن نختبر السطح قبل الرحيل حتى نضمن جودة خدمة العزل التي قدمناها، ولا مشكلة من العودة مرة أخرى لو ظهر أي خلل لا يرضيك.
                </p>

                <h6>
                لعميل العزيز نحن في انتظار تواصلك معنا على مدار الساعة، فشركتنا فيوتشر  بين الأفضل هي الافضل في المجال، وذلك بسبب  المعايير والعوامل التي نلتزم بها، والتي جعلتنا بحق شركة خدمات تتربع وحدها على عرش التميز والتفوق.
Contact Future:
<Link to='https://wa.me/+0537134530'>0537134530</Link>
                </h6>
            </div>


        </div>

        </div>

    </div>
  )
}

export default Clients